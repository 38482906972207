const type = [
  {
    id: 2,
    name: "TITLE DETAIL",
    header: "Title Dropdown Detail",
    updated: "UpdateTitleChoice",
  },
  {
    id: 21,
    name: "Gender DETAIL",
    header: "Gender Dropdown Detail",
    updated: "UpdateGenderChoice",
  },
  {
    id: "document",
    name: "Gender DETAIL",
    header: "Gender Dropdown Detail",
    updated: "UpdateGenderChoice",
  },
];

export default type;
